"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.AjaxForm = function AjaxForm() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var empty = 0;
    var allInput = document.querySelectorAll(".require");
    var buttonFinish = document.querySelector(".saveFormFinish");
    var buttonSave = document.querySelector(".saveForm");

    if (allInput) {
      allInput.forEach(function (e) {
        if (e.value.length !== 0) {
          empty += 1;
        }
        if (empty == allInput.length) {
          if (buttonFinish) buttonFinish.removeAttribute("disabled");
          if (buttonSave) buttonSave.classList.remove("save-only");
        } else {
          if (buttonFinish) buttonFinish.setAttribute("disabled", "disabled");
          if (buttonSave) buttonSave.classList.add("save-only");
        }
      });
      allInput.forEach(function (input) {
        input.addEventListener('keyup', function () {
          empty = 0;
          allInput.forEach(function (e) {
            if (e.value.length !== 0) {
              empty += 1;
            }
            if (empty == allInput.length) {
              if (buttonFinish) buttonFinish.removeAttribute("disabled");
              if (buttonSave) buttonSave.classList.remove("save-only");
            } else {
              if (buttonFinish) buttonFinish.setAttribute("disabled", "disabled");
              if (buttonSave) buttonSave.classList.add("save-only");
            }
          });
        });
      });
    }

    $('.hero-account').click(function () {
      $(".sub-menu-account").toggleClass("is-active");
      $(".overlay-site").toggleClass("is-active");
    });

    if ($('[data-toggle="tooltip"]')) {
      $('[data-toggle="tooltip"]').tooltip();
    }

    $(".overlay-site").click(function () {
      if ($(".overlay-site").hasClass("is-active")) {
        $(".sub-menu-account").removeClass("is-active");
        $(".navigation-mobile").removeClass("is-active");
        $(".sub-menu-infos").removeClass("is-active");
        $(".overlay-site").removeClass("is-active");
      };
    });

    $.uploadPreview({
      input_field: "#image-upload",
      preview_box: "#image-preview",
      label_field: "#image-label",
      label_default: "Choose File",
      label_selected: "Changer de photo",
      no_label: false
    });

    if ($("#image-upload")) {
      $("#image-upload").blur(function () {
        if ($(this)[0].files.length && $(this)[0].files[0].size > 10000000) {
          swal({
            title: 'Le fichier selectionné dépasse 10MB',
            icon: "warning",
            button: 'Retour'
          });
          $(this)[0].val("");
        } else {
          console.log($(this)[0].files.length);
          $.uploadPreview({
            input_field: "#image-upload", // Default: .image-upload
            preview_box: "#image-preview", // Default: .image-preview
            label_field: "#image-label", // Default: .image-label
            label_default: "Choisir une photo", // Default: Choose File
            label_selected: "Changer de photo", // Default: Change File
            no_label: false // Default: false
          });

          if ($(this)[0].files.length === 0) {
            $('#image-preview')[0].removeAttribute('style');
            $('#image-label')[0].innerHTML = '<span class="label-description">Glisser-déposer votre fichier ici ou</span><span class="label-button">Ajouter une photo</span>';
            $(this)[0].setAttribute('required', 'required');
            $(this).removeClass('has-file');
          } else {
            $(this)[0].removeAttribute('required');
          }
        }
      });
    }

    if ($("#bulletin-upload")) {
      $("#bulletin-upload").change(function () {
        if ($(this)[0].files.length > 0) {
          if ($(this)[0].files[0].size > 10000000) {
            swal({
              title: 'Le fichier selectionné dépasse 10MB',
              icon: "warning",
              button: 'Retour'
            });
            $(this).val("");
          } else {
            var fileName = $(this).val().split('/').pop().split('\\').pop();
            $('.present-bulletin').addClass('is-active');
            $('.present-bulletin strong').html(fileName);
          }
        } else {
          $('.present-bulletin').removeClass('is-active');
        }
      });
    }

    $('.site-btn.printer').click(function () {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');

      mywindow.document.write('<html><head><title>' + document.title + '</title>');
      mywindow.document.write('</head><body >');
      mywindow.document.write($('.form-result.for-print').html());
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      // mywindow.close();

      return true;
    });

    $('input[type="number"]').on('keyup', function () {
      var v = $(this).val();
      var min = parseInt($(this).attr('min'));
      var max = parseInt($(this).attr('max'));

      if (min) {
        if (v < min || v === '') {
          $(this).val(min);
        }
      }

      if (max) {
        if (v > max) {
          $(this).val(max);
        }
      }

      if (v === '') {
        $(this).val(0);
      }
    });

    $('.form-number-down').click(function () {
      var $input = $(this).next('input[type="number"]');
      $input.get(0).stepDown();
    });

    $('.form-number-up').click(function () {
      var $input = $(this).prevAll('input[type="number"]').first();
      $input.get(0).stepUp();
    });

    if ($("#main-form").find('input:not(.is-disabled)').is('[disabled=disabled]') && $("#main-form").find('textarea:not(.is-disabled)').is('[disabled=disabled]')) {
      $('.cta-lock').find('.icon').removeClass('icon-unlock');
      $('.cta-lock').find('.icon').addClass('icon-locker');
    } else {
      $('.cta-lock').find('.icon').removeClass('icon-locker');
      $('.cta-lock').find('.icon').addClass('icon-unlock');
    }

    $('.cta-lock').click(function () {

      if ($("#main-form").find('input:not(.is-disabled)').is('[disabled=disabled]') && $("#main-form").find('textarea:not(.is-disabled)').is('[disabled=disabled]')) {
        swal({
          title: 'Souhaitez-vous réellement modifier les informations du formulaire ?',
          icon: "info",
          buttons: ['Non', 'Oui']
        }).then(function (isConfirm) {
          if (isConfirm) {
            $("#main-form").find('input:not(.is-disabled)').removeAttr("disabled").end().find('textarea:not(.is-disabled)').removeAttr("disabled").end();

            $('.cta-lock').find('.icon').removeClass('icon-locker');
            $('.cta-lock').find('.icon').addClass('icon-unlock');
          }
        });
      } else {
        swal({
          title: 'Voulez-vous désactiver la modification du formulaire ?',
          icon: "info",
          buttons: ['Non', 'Oui']
        }).then(function (isConfirm) {
          if (isConfirm) {
            $("#main-form").find('input:not(.is-disabled)').attr("disabled", true).end().find('textarea:not(.is-disabled)').attr("disabled", true).end();

            $('.cta-lock').find('.icon').removeClass('icon-unlock');
            $('.cta-lock').find('.icon').addClass('icon-locker');
          }
        });
      }
    });

    if ($("#propJurPro").is(':checked')) {
      $('#hiddenPropText').find('span').text(' Candidature proposée au Jury Provincial');
    } else {
      if ($(".alredySend").length) {
        $('#hiddenPropText').find('span').text(' Une proposition au provincial à déjà été faite pour cette categorie');
      } else {
        $('#hiddenPropText').find('span').text(' Proposer la candidature proposée au Jury Provincial');
      }
    }

    if ($("#victoryProvincal").is(':checked')) {
      $('#hiddenPropTextVict').find('span').text(' Candidature désignée comme gagnante');
    } else {
      if ($(".alredySend").length) {
        $('#hiddenPropTextVict').find('span').text(' Un gagnant à déjà été nominé pour cette categorie');
      } else {
        $('#hiddenPropTextVict').find('span').text(' Désigner la candidature comme gagnante');
      }
      $('#hiddenPropTextVict').find('span').text(' Désigner la candidature comme gagnante');
    }

    $("#propJurPro").change(function () {

      var form = $("#second-form");
      var url = form.attr('action');
      var formData = new FormData($("#second-form")[0]);

      var notesElements = $('.form-note:not(:disabled)');

      if (notesElements.length > 0) {
        var noteMoyenneReg = 0;
        var noteVerification = true;

        notesElements.each(function (index, item) {
          var element = $(item);
          if (element.val() !== '') {
            noteMoyenneReg += parseInt(element.val());
          } else {
            noteVerification = false;
          }
        });

        if (noteVerification) {
          $.ajax({
            type: "POST",
            url: url,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            dataType: 'json'
          }).always(function () {

            if ($("#propJurPro").is(':checked')) {
              $('#hiddenPropText').find('span').text(' Candidature proposée au Jury Provincial');
              swal({
                title: 'Candidature proposée au Jury Provincial',
                icon: "success"
              });
            } else {
              if ($(".alredySend")) {
                $('#hiddenPropText').find('span').text(' Une proposition au provincial à déjà été faite pour cette categorie');
                swal({
                  title: 'Une proposition au provincial à déjà été faite pour cette categorie',
                  icon: "warning"
                });
              } else {
                $('#hiddenPropText').find('span').text(' Proposer la candidature proposée au Jury Provincial');
                swal({
                  title: 'Candidature retirée au Jury Provincial',
                  icon: "warning"
                });
              }
            }
          });
        } else {
          $(this).attr('checked', false);
          swal({
            title: 'Les notes sont requises',
            icon: "warning",
            button: 'Retour'
          });
        }
      } else {
        $.ajax({
          type: "POST",
          url: url,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          dataType: 'json'
        }).always(function () {

          if ($("#propJurPro").is(':checked')) {
            $('#hiddenPropText').find('span').text(' Candidature proposée au Jury Provincial');
            swal({
              title: 'Candidature proposée au Jury Provincial',
              icon: "success"
            });
          } else {
            if ($(".alredySend")) {
              $('#hiddenPropText').find('span').text(' Une proposition au provincial à déjà été faite pour cette categorie');
              swal({
                title: 'Une proposition au provincial à déjà été faite pour cette categorie',
                icon: "warning"
              });
            } else {
              $('#hiddenPropText').find('span').text(' Proposer la candidature proposée au Jury Provincial');
              swal({
                title: 'Candidature retirée au Jury Provincial',
                icon: "warning"
              });
            }
          }
        });
      }
    });

    $("#victoryProvincal").change(function () {

      var form = $("#second-form");
      var url = form.attr('action');

      var formData = new FormData($("#second-form")[0]);

      var notesElements = $('input[name="fields[noteMoyProv]"]:not(:disabled)');

      $.merge(notesElements, $('.form-note:not(:disabled)'));

      if (notesElements.length > 0) {
        var noteMoyenneProv = 0;
        var noteVerification = true;

        notesElements.each(function (index, item) {
          var element = $(item);
          if (element.val() !== '') {
            noteMoyenneProv += parseInt(element.val());
          } else {
            noteVerification = false;
          }
        });

        if (noteVerification) {
          $.ajax({
            type: "POST",
            url: url,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            dataType: 'json'
          }).always(function () {

            if ($("#victoryProvincal").is(':checked')) {
              $('#hiddenPropTextVict').find('span').text(' Candidature désignée comme gagnante');
              swal({
                title: 'Candidature désignée comme gagnante',
                icon: "success"
              });
            } else {
              $('#hiddenPropTextVict').find('span').text(' Désigner la candidature comme gagnante');
              swal({
                title: 'Candidature retirée de la victoire',
                icon: "warning"
              });
            }
          });
        } else {
          $(this).attr('checked', false);
          swal({
            title: 'Les notes sont requises',
            icon: "warning",
            button: 'Retour'
          });
        }
      } else {
        $.ajax({
          type: "POST",
          url: url,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          dataType: 'json'
        }).always(function () {

          if ($("#victoryProvincal").is(':checked')) {
            $('#hiddenPropTextVict').find('span').text(' Candidature désignée comme gagnante');
            swal({
              title: 'Candidature désignée comme gagnante',
              icon: "success"
            });
          } else {
            $('#hiddenPropTextVict').find('span').text(' Désigner la candidature comme gagnante');
            swal({
              title: 'Candidature retirée de la victoire',
              icon: "warning"
            });
          }
        });
      }
    });

    // $('.save-school-form').click(function(e) {
    //   e.preventDefault();
    //   var form = $("#main-form");

    //   var notesElements = $('.form-note:not(:disabled)');

    //   if (notesElements.length > 0) {
    //     var noteMoyenneReg = 0;
    //     var noteVerification = true;

    //     notesElements.each(function(index, item) {
    //       var element = $(item);
    //       if (element.val() !== '') {
    //         noteMoyenneReg += parseInt(element.val());
    //       } else {
    //         noteVerification = false;
    //       }
    //     });

    //     if (noteVerification) {
    //       $('[name="fields[noteMoyReg]"]').val(noteMoyenneReg);

    //       form.submit();
    //     } else {
    //         swal({
    //           title: 'Les notes sont requises',
    //           icon: "warning",
    //           button: 'Retour'
    //         });
    //     }
    //   } else {
    //       form.submit();
    //   }
    // });

    $(".deleteForm").click(function (e) {
      e.preventDefault();
      var inputStatut = $('input[name="fields[formStatut]"]');
      if (inputStatut) {
        inputStatut.val("uncompleted");
      }
      var url = 'entries/delete-entry';
      var formData = new FormData($("#third-form")[0]);
      swal({
        title: "Etes-vous certain de vouloir supprimer cette inscription?",
        icon: "warning",
        buttons: ["Annuler", "Confirmer"],
        dangerMode: true
      }).then(function (willDelete) {
        if (willDelete) {
          $.ajax({
            type: "POST",
            url: url,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            dataType: 'json',
            success: function success() {
              swal({
                title: 'Les informations ont bien été supprimées.',
                icon: "success",
                button: 'Continuer'

              }).then(function () {
                window.location.href = "/";
              });
            },
            error: function error(e) {
              console.log(e);
              swal({
                title: 'Désolé, une erreur est survenue.',
                icon: "error",
                button: 'Continuer'
              });
            }
          });
        }
      });
    });

    $(".saveForm").click(function (e) {
      e.stopPropagation();
      e.preventDefault();
      var inputStatut = $('input[name="fields[formStatut]"]');
      if (inputStatut && inputStatut.val() !== "completed") {
        inputStatut.val("processing");
      }
      var form = $("#main-form");
      var url = form.find('input[name="action"]').val();
      var photo = $('#image-upload');
      var bulletin = $('#bulletin-upload');

      var nameElements = $('input.is-name:not(:disabled)');
      var namesVerification = true;

      if (nameElements.length > 0) {
        nameElements.each(function (index, item) {
          var element = $(item);
          if (element.val() === '') {
            namesVerification = false;
          }
        });
      }

      var notesElements = $('.form-note:not(:disabled)');

      if (notesElements.length > 0) {
        var noteMoyenneReg = 0;
        var noteVerification = true;

        notesElements.each(function (index, item) {
          var element = $(item);
          if (element.val().length !== 0) {
            noteMoyenneReg += parseInt(element.val());
          } else {
            noteVerification = false;
          }
        });

        if (noteVerification && namesVerification) {
          $('[name="fields[noteMoyReg]"]').val(noteMoyenneReg);
          $('[name="fields[formStatutRegional]"]').val('completed');
          var formData = new FormData($("#main-form")[0]);
          $.ajax({
            type: "POST",
            url: url,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            dataType: 'json',
            success: function success() {
              swal({
                title: 'Les informations ont bien été sauvegardées.',
                icon: "success",
                button: 'Continuer'
              }).then(function () {
                window.location.href = "/";
              });
            },
            error: function error() {
              swal({
                title: 'Désolé, une erreur est survenue.',
                icon: "error",
                button: 'Continuer'
              });
            }
          });
        } else {
          if (!noteVerification) {
            swal({
              title: 'Les notes sont requises',
              icon: "warning",
              button: 'Retour'
            });
          } else if (!namesVerification) {
            swal({
              title: 'Les champs "nom" sont requis',
              icon: "warning",
              button: 'Retour'
            });
          }
        }
      } else {
        // if (photo && bulletin || photo || bulletin) {

        if ($('[data-no-check]').length && namesVerification) {
          var formData = new FormData($("#main-form")[0]);
          $.ajax({
            type: "POST",
            url: url,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            dataType: 'json',
            success: function success() {
              swal({
                title: 'Les informations ont bien été sauvegardées.',
                icon: "success",
                button: 'Continuer'
              }).then(function () {
                window.location.href = "/";
              });;
            },
            error: function error() {
              swal({
                title: 'Désolé, une erreur est survenue.',
                icon: "error",
                button: 'Retour'
              });
            }
          });
        } else {
          if (!namesVerification) {
            swal({
              title: 'Les champs "nom" sont requis',
              icon: "warning",
              button: 'Retour'
            });
          }

          if (namesVerification) {
            var formData = new FormData($("#main-form")[0]);
            $.ajax({
              type: "POST",
              url: url,
              data: formData,
              cache: false,
              contentType: false,
              processData: false,
              dataType: 'json',
              success: function success() {
                swal({
                  title: 'Les informations ont bien été sauvegardées.',
                  icon: "success",
                  button: 'Continuer'
                }).then(function () {
                  window.location.href = "/";
                });
              },
              error: function error() {
                swal({
                  title: 'Désolé, une erreur est survenue.',
                  icon: "error",
                  button: 'Retour'
                });
              }
            });
          }
        }
        // }
      }
    });

    $(".saveFormFinish").click(function (e) {
      e.preventDefault();
      var inputVictory = $('input[name="fields[selectVictory]"]');
      var inputEnvoyeProvincial = $('input[name="fields[generalEnvoyeProvincial]"]');
      var inputStatutRegional = $('input[name="fields[formStatutRegional]"]');
      var inputStatut = $('input[name="fields[formStatut]"]');
      // Provincial
      if (inputVictory.length) {
        console.log('victory');
        inputVictory.val("1");
      }
      // Regional
      if (inputEnvoyeProvincial.length) {
        inputEnvoyeProvincial.val("1");
        inputStatutRegional.val("submitted");
      }
      // Ecole
      else if (inputStatut.length) {
          if (inputStatut.val() !== "completed") {
            inputStatut.val("completed");
            inputStatutRegional.val("processing");
          }
        }
      var form = $("#main-form");
      var url = form.find('input[name="action"]').val();

      var notesElements = $('.form-note:not(:disabled)');

      var nameElements = $('input.is-name:not(:disabled)');
      var namesVerification = true;

      if (nameElements.length > 0) {
        nameElements.each(function (index, item) {
          var element = $(item);
          if (element.val() === '') {
            namesVerification = false;
          }
        });
      }

      var photo = $('#image-upload');
      var bulletin = $('#bulletin-upload');

      if (notesElements.length > 0) {
        var noteMoyenneReg = 0;
        var noteVerification = true;

        notesElements.each(function (index, item) {
          var element = $(item);
          if (element.val() !== '') {
            noteMoyenneReg += parseInt(element.val());
          } else {
            noteVerification = false;
          }
        });

        if (noteVerification && namesVerification) {
          $('[name="fields[noteMoyReg]"]').val(noteMoyenneReg);

          var formData = new FormData($("#main-form")[0]);

          $.ajax({
            type: "POST",
            url: url,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            dataType: 'json',
            success: function success() {
              swal({
                title: 'Les informations ont bien été sauvegardées. Vous allez être redirigé vers l\'accueil',
                icon: "success",
                button: 'Terminer'
              }).then(function (isConfirm) {
                if (isConfirm) {
                  window.location.href = '/';
                }
              });
            },
            error: function error() {
              swal({
                title: 'Désolé, une erreur est survenue.',
                icon: "error",
                button: 'Continuer'
              });
            }
          });
        } else {
          if (!noteVerification) {
            swal({
              title: 'Les notes sont requises',
              icon: "warning",
              button: 'Retour'
            });
          } else if (!namesVerification) {
            swal({
              title: 'Les champs "nom" sont requis',
              icon: "warning",
              button: 'Retour'
            });
          }
        }
      } else {
        if ($('[data-no-check]').length && namesVerification) {
          var formData = new FormData($("#main-form")[0]);

          $.ajax({
            type: "POST",
            url: url,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            dataType: 'json',
            success: function success() {
              swal({
                title: 'Les informations ont bien été sauvegardées. Vous allez être redirigé vers l\'accueil',
                icon: "success",
                button: 'Terminer'
              }).then(function (isConfirm) {
                if (isConfirm) {
                  window.location.href = '/';
                }
              });
            },
            error: function error() {
              swal({
                title: 'Désolé, une erreur est survenue.',
                icon: "error",
                button: 'Retour'
              });
            }
          });
        } else {
          if (!namesVerification) {
            swal({
              title: 'Les champs "Nom et Prénom" sont requis',
              icon: "warning",
              button: 'Retour'
            });
          }

          if (namesVerification) {
            var formData = new FormData($("#main-form")[0]);

            $.ajax({
              type: "POST",
              url: url,
              data: formData,
              cache: false,
              contentType: false,
              processData: false,
              dataType: 'json',
              success: function success() {
                swal({
                  title: 'Les informations ont bien été sauvegardées. Vous allez être redirigé vers l\'accueil',
                  icon: "success",
                  button: 'Terminer'
                }).then(function (isConfirm) {
                  if (isConfirm) {
                    window.location.href = '/';
                  }
                });
              },
              error: function error() {
                swal({
                  title: 'Désolé, une erreur est survenue.',
                  icon: "error",
                  button: 'Retour'
                });
              }
            });
          }
        }
      }
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();